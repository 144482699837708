/**
 * Created by Mikhail Menshenin on 18.06.2024
 */

cleverapps.override(cleverapps.Tool.game, {
    addRoll: function () {
        var yatzy = Game.currentGame;

        yatzy.addRoll();
    },

    "make suggested rolls": {
        triple: function () {
            var yatzy = Game.currentGame;
            var selectedScore = cleverapps.shuffle(cleverapps.rangeArray(1, 6)).shift();
            var otherScores = cleverapps.rangeArray(1, 6).filter(function (score) {
                return score !== selectedScore;
            });

            yatzy.suggestedRolls = cleverapps.shuffle(cleverapps.arrayFill(3, selectedScore).concat(cleverapps.shuffle(otherScores).slice(0, 2)));
            yatzy.addRoll();
            yatzy.roll();
        },

        quadruple: function () {
            var yatzy = Game.currentGame;
            var selectedScore = cleverapps.shuffle(cleverapps.rangeArray(1, 6)).shift();
            var otherScores = cleverapps.rangeArray(1, 6).filter(function (score) {
                return score !== selectedScore;
            });

            yatzy.suggestedRolls = cleverapps.shuffle(cleverapps.arrayFill(4, selectedScore).concat(cleverapps.shuffle(otherScores).slice(0, 1)));
            yatzy.addRoll();
            yatzy.roll();
        },

        twoPairs: function () {
            var yatzy = Game.currentGame;
            var pairScores = cleverapps.shuffle(cleverapps.rangeArray(1, 6)).slice(-2);
            var otherScores = cleverapps.rangeArray(1, 6).filter(function (score) {
                return !pairScores.includes(score);
            });

            yatzy.suggestedRolls = cleverapps.shuffle(cleverapps.arrayFill(2, pairScores[0]).concat(cleverapps.arrayFill(2, pairScores[1])).concat(cleverapps.shuffle(otherScores).slice(0, 1)));
            yatzy.addRoll();
            yatzy.roll();
        },

        roll1to4: function () {
            var suggested = cleverapps.rangeArray(1, 4);
            var double = cleverapps.Random.choose(suggested);
            suggested.push(double);

            var yatzy = Game.currentGame;
            yatzy.suggestedRolls = cleverapps.shuffle(suggested);
            yatzy.addRoll();
            yatzy.roll();
        },

        roll2to5: function () {
            var suggested = cleverapps.rangeArray(2, 5);
            var double = cleverapps.Random.choose(suggested);
            suggested.push(double);

            var yatzy = Game.currentGame;
            yatzy.suggestedRolls = cleverapps.shuffle(suggested);
            yatzy.addRoll();
            yatzy.roll();
        },

        roll3to6: function () {
            var suggested = cleverapps.rangeArray(3, 6);
            var double = cleverapps.Random.choose(suggested);
            suggested.push(double);

            var yatzy = Game.currentGame;
            yatzy.suggestedRolls = cleverapps.shuffle(suggested);
            yatzy.addRoll();
            yatzy.roll();
        }
    },

    setScore: function () {
        var yatzy = Game.currentGame;

        var callback = function (score) {
            yatzy.score.setPoints(parseInt(score) || 0);
        };

        displayToolPrompt(callback, "Set score", 100);
    },

    setBonus: function () {
        var yatzy = Game.currentGame;

        var callback = function (bonus) {
            yatzy.setBonus(bonus);
        };

        displayToolPrompt(callback, "Set score", 100);
    }
});