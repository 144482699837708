/**
 * Created by olga on 02.08.2024
 */

Simple.prototype.amountLevelStars = function (game) {
    var score = game.score.points;
    if (score > 200) {
        return 3;
    }
    if (score > 150) {
        return 2;
    }
    if (score > 100) {
        return 1;
    }
    return 0;
};
