/**
 * Created by Mikhail Menshenin on 20.06.2024
 */

var TournamentWindow = MissionWindow.extend({
    onWindowLoaded: function (mission) {
        var styles = cleverapps.styles.TournamentWindow;
        this._super(mission, styles);

        var timer = this.createTimer();
        if (timer) {
            timer.setPositionRound(styles.timer);
            this.window.addChild(timer);
        }
    },

    createContent: function () {
        var styles = cleverapps.styles.TournamentWindow;

        this.tableView = this.createTable();
        var items = [];

        this.animationBlock = this.createAnimationBlock(this.tableView.width);
        items.push(this.animationBlock);
        items.push(this.tableView);

        return new cleverapps.Layout(items, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });
    },

    createAnimationBlock: function (tableWidth) {
        var styles = cleverapps.styles.TournamentWindow.animationBlock;
        var bundle = bundles[this.mission.getWindowBundleName()];

        var animation = new cleverapps.Spine(bundle.jsons.json);
        animation.setAnimation(0, "animation", true);

        var text = cleverapps.UI.generateOnlyText("Missions.LetterTournament.InfoText", cleverapps.styles.FONTS.TOURNAMENT_WINDOW_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setWrapWidth(styles.text.wrap);
        text.fitTo(styles.text.width, Math.min(styles.text.height, animation.height));

        var layout = new cleverapps.Layout([animation, text], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            padding: styles.padding
        });
        layout.setPositionRound(styles.layoutPosition);

        var bg = cleverapps.UI.createScale9Sprite(bundle.frames.blue_block_png);
        bg.setContentSize(tableWidth, layout.height);
        bg.addChild(layout);

        return bg;
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    TOURNAMENT_WINDOW_TEXT: {
        size: 28,
        lineHeight: 30,
        color: new cc.Color(28, 101, 189)
    }

});

cleverapps.styles.TournamentWindow = cleverapps.overrideStyles(cleverapps.styles.MissionWindow, {

    window: {
        margin: 46,
        padding: {
            bottom: 50
        }
    },

    button: {
        width: 260,
        height: 100,
        type: cleverapps.styles.UI.Button.Images.button_green
    },

    table: {
        height: 520
    },

    animationBlock: {
        margin: 40,
        padding: 20,
        layoutPosition: {
            x: { align: "center", dx: -10 },
            y: { align: "center" }
        },
        text: {
            wrap: 390,
            width: 460,
            height: 200
        }
    },

    timer: {
        x: { align: "center" },
        y: { align: "bottom", dy: -55 }
    }
});
