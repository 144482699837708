/**
 * Created by Slava on 08.04.2024
 */

VictoryMessageAction = cleverapps.extendFunc(VictoryMessageAction, function (f) {
    if (Game.currentGame.outcome !== GameBase.OUTCOME_VICTORY) {
        f();
        return;
    }

    cleverapps.audio.playSound(bundles.main.urls.congratulate_sfx);

    var game = Game.currentGame;
    var scene = cleverapps.scenes.getRunningScene();

    game.counter.inc();
    cleverapps.timeouts.setTimeout(function () {
        var messageView = new GameMessageView("GameMessage.Victory");
        scene.addChild(messageView);

        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            messageView.content.setFont(cleverapps.styles.FONTS.GAME_MESSAGE_TEXT_VERTICAL);
            messageView.content.setupChildren = function () {
                messageView.content.setPositionRound(cleverapps.styles.VictoryMessage.position.vertical.text);
            };
            messageView.content.setupChildren();
        }

        var animation = new cleverapps.Spine(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? bundles.victory_window.jsons.victory_message_animation_vertical : bundles.victory_window.jsons.victory_message_animation);
        animation.setAnimation(0, "animation_3", false);
        animation.setPositionRound(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? cleverapps.styles.VictoryMessage.position.vertical : cleverapps.styles.VictoryMessage.position.horizontal);
        messageView.background.addChild(animation);

        cleverapps.timeouts.setTimeout(function () {
            console.log("GameMessage.Victory callback");
            game.counter.dec();
            f();
        }, 1500);
    }, 200);
});

GameMessageView.prototype.createBackground = cleverapps.extendFunc(GameMessageView.prototype.createBackground, function () {
    var background = this.background = new cc.Scale9Sprite(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? bundles.gamemessage.frames.gamemessage_bg_vertical_png : bundles.gamemessage.frames.gamemessage_bg_png);
    background.setLocalZOrder(-1);
    return background;
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    GAME_MESSAGE_TEXT_VERTICAL: {
        size: 45,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.VictoryMessage = {
    position: {
        vertical: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 12 },
            text: {
                x: { align: "center", dx: 90 },
                y: { align: "center" }
            }
        },

        horizontal: {
            x: { align: "center", dx: -50 },
            y: { align: "center", dy: 62 }
        }
    }
};