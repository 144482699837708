/*
 * Created by mac on 2/28/23
 */

var Yatzy = function (level, options) {
    GameBase.call(this, level, options);

    this.level = level;
    this.options = options;

    var save = this.savedGame || {};
    var content = this.levelContent || {};

    this.slots = [];
    var savedSlots = cleverapps.override(content.slots || {}, save.slots || {});
    for (var i = 0; i < 13; i++) {
        this.slots.push(new Slot(i, savedSlots[i]));
    }

    this.dices = [];
    var savedDices = cleverapps.override(content.dices || {}, save.dices || {});
    for (var j = 0; j < 5; j++) {
        this.dices.push(new Dice(j, savedDices[j]));
    }

    this.score.setPoints(save.score || content.score || 0);
    this.opponentScore = save.opponentScore ? new Score(save.opponentScore) : undefined;

    this.bonus = save.bonus || content.bonus || 0;
    this.bonusReceived = save.bonusReceived || content.bonusReceived || false;

    var goal = this.goal = this.level.content.goal ? new Goal(this.level.content.goal, save.goal) : undefined;
    if (goal) {
        goal.setAmount(this.score.points);
    }

    this.rolls = save.rolls !== undefined ? save.rolls : Yatzy.MAX_ROLLS;
    this.rolled = false;
    this.rollCount = save.rollCount || 0;
    this.playCount = save.playCount || 0;

    this.counter.registerStage(1, this.afterRoll.bind(this));
    this.counter.registerStage(2, this.checkExclamations.bind(this));
    this.counter.registerStage(11, this.hideDicesStage.bind(this));
    this.counter.registerStage(12, this.calculatePlayScore.bind(this));
    this.counter.registerStage(13, this.checkWin.bind(this));
    this.counter.registerStage(14, this.nextRound.bind(this));
    this.counter.registerStage(90, aisensia.tutorial.processStage.bind(aisensia.tutorial));
    this.counter.registerStage(100, this.autoRoll.bind(this));
};

var Game = Yatzy;

Yatzy.prototype = Object.create(GameBase.prototype);
Yatzy.prototype.constructor = Yatzy;

GameBase.prototype.startCounter = function (f) {
    if (!cleverapps.config.editorMode) {
        this.counter.turnOn();
    }

    cleverapps.userStatus.reportUserAction();
    f();
};

GameBase.prototype.listIntroActions = function () {
    return [
        this.prepareBoosters.bind(this),
        this.showScreen.bind(this),
        this.updateRestoreProgress.bind(this),
        this.beforeGameStart.bind(this),
        this.showStartGameMessage.bind(this),
        this.startCounter.bind(this),
        this.startTutorial.bind(this),
        this.showDailyLevelWindow.bind(this),
        this.executeActionsBefore.bind(this),
        this.runFPS.bind(this)
    ];
};

Yatzy.prototype.setBasicReward = function () {
    this.basicReward = 5;
};

Yatzy.prototype.addBasicReward = function () {
    [0, 101, 151, 201, 251].forEach(function (threshold) {
        if (this.score.points >= threshold) {
            this.addSoftReward(this.basicReward);
        }
    }.bind(this));
};

Yatzy.prototype.getInfo = function () {
    var info = GameBase.prototype.getInfo.call(this);

    info.slots = this.slots.map(function (slot) {
        return slot.getInfo();
    });
    info.dices = this.dices.map(function (dice) {
        return dice.getInfo();
    });
    info.score = this.score.getPoints();
    info.opponentScore = this.opponentScore ? this.opponentScore.getPoints() : undefined;
    if (this.goal) {
        info.goal = this.goal.getInfo();
    }
    info.rolls = this.rolls;
    info.bonus = this.bonus;

    if (aisensia.tutorial.isActive()) {
        info.tutorial = aisensia.tutorial.getInfo();
    }

    if (this.rollCount) {
        info.rollCount = this.rollCount;
    }

    if (this.playCount) {
        info.playCount = this.playCount;
    }

    return info;
};

Yatzy.prototype.levelWithTutorial = function () {
    var currentLevel = this.level.getHumanReadableNumber();
    return currentLevel === 1 || this.savedGame.tutorial;
};

Yatzy.prototype.displayTutorial = function (f) {
    var saveData = this.savedGame.tutorial || {};
    var scenarioName = saveData.name || StartTutorialScenario.name;
    aisensia.tutorial.run(scenarioName, this.savedGame.tutorial, f);
};

Yatzy.prototype.autoRoll = function () {
    if (this.outcome !== GameBase.OUTCOME_UNKNOWN) {
        return;
    }

    if (aisensia.tutorial.isActive()) {
        var step = aisensia.tutorial.step();
        if (step && step.noAutoRoll) {
            return;
        }
    }

    if (this.fullRolls()) {
        this.roll(true);
    }
};

Yatzy.prototype.afterRoll = function () {
    if (!this.rolled) {
        return;
    }

    var yatzyPlayed = this.slots[Slot.YATZY].used;

    this.slots.forEach(function (slot) {
        if (!slot.used) {
            slot.setScore(slot.calcScore(yatzyPlayed));
        }
    });
};

Yatzy.prototype.checkExclamations = function () {
    if (!this.rolled) {
        return;
    }

    this.rolled = false;

    var message;

    this.slots.forEach(function (slot) {
        if (!slot.used && slot.score === Slot.YATZY_SCORE) {
            message = "YATZY";
        }
    });

    if (message) {
        cleverapps.exclamation.show(message);

        cleverapps.audio.playSound(bundles.game.urls.yatzy);

        this.counter.setTimeout(function () {
            var scene = cleverapps.scenes.getRunningScene();
            scene.exclamationView.animation.removeFromParent();
            delete scene.exclamationView.animation;
        }, 1500);
    }
};

Yatzy.prototype.resetDices = function () {
    this.dices.forEach(function (dice) {
        dice.setChosen(false);
    });
};

Yatzy.prototype.fullRolls = function () {
    return this.rolls === Yatzy.MAX_ROLLS;
};

Yatzy.prototype.noRollsLeft = function () {
    return this.rolls === 0;
};

Yatzy.prototype.play = function (slot) {
    slot.select();
    this.resetDices();
    this.playedSlot = slot;

    this.counter.inc();
    this.сollectMarks(function () {
        this.trigger("play", slot, function () {
            this.counter.dec();
        }.bind(this));
    }.bind(this));

    if (aisensia.tutorial.isActive()) {
        aisensia.tutorial.waitFinishStep();
    }

    this.playCount++;

    cleverapps.audio.playSound(bundles.game.urls.click_roll);
};

Yatzy.prototype.hideDicesStage = function () {
    if (!this.playedSlot) {
        return;
    }

    this.dices.forEach(function (dice) {
        dice.hide();
    });
};

Yatzy.prototype.calculatePlayScore = function () {
    if (!this.playedSlot) {
        return;
    }
    var playedSlot = this.playedSlot;
    delete this.playedSlot;

    var addScore = playedSlot.score;
    if (addScore) {
        this.score.addPoints(addScore);
    }
    if (this.goal) {
        this.goal.setAmount(this.score.points);
    }

    if (this.opponentScore) {
        var opponentScore = this.setOpponentResult();
        this.opponentScore.addPoints(opponentScore);
    }

    this.calcFirstColumnScore();

    playedSlot.confirm();
    this.nextRoundReady = true;

    this.triggerTaskEvents(playedSlot);
};

Yatzy.prototype.nextRound = function () {
    if (!this.nextRoundReady) {
        return;
    }

    this.nextRoundReady = false;

    this.counter.setTimeout(function () {
        this.rolls = Yatzy.MAX_ROLLS;
        this.trigger("changeRolls");
    }.bind(this), 500);
};

Yatzy.prototype.checkWin = function () {
    if (this.calculateRemaining() === 0) {
        if (this.goal) {
            if (this.goal.isCompleted()) {
                this.win();
            } else {
                this.lose();
            }
        } else {
            this.win();
        }
    }
};

Yatzy.prototype.calculateRemaining = function () {
    return this.slots.filter(function (slot) {
        return !slot.used;
    }).length;
};

Yatzy.prototype.removeRoll = function (auto) {
    this.rolls--;
    if (!auto) {
        this.rollCount++;
    }
    this.trigger("changeRolls");
};

Yatzy.prototype.addRoll = function () {
    this.rolls++;
    this.trigger("changeRolls");
};

Yatzy.prototype.getProlongationOffer = function () {
    if (!levels.user.checkAvailable(cleverapps.Availables.PROLONGATION_OFFER)) {
        return;
    }

    if (this.getRolls() === 0 && !aisensia.tutorial.isActive() && (cleverapps.forces.isShown(Forces.FREE_PROLONGATION_FORCE.id) || this.prolongationScenario)) {
        return this.prolongation.getOffer(Prolongation.TYPES.MOVES);
    }
};

Yatzy.prototype.getRolls = function () {
    return this.rolls;
};

Yatzy.prototype.setMoves = function (moves) {
    this.rolls = moves;
    this.trigger("changeRolls");
};

Yatzy.prototype.setBonus = function (bonus) {
    if (!this.bonusReceived && this.bonus !== bonus) {
        this.bonus = bonus;
        this.trigger("updateBonus");

        if (this.bonus >= Yatzy.BONUS_GOAL) {
            this.bonusReceived = true;
            this.counter.inc();
            this.trigger("receiveBonus", function () {
                this.score.addPoints(Yatzy.BONUS_REWARD, true);
                this.counter.dec();
            }.bind(this));
            cleverapps.eventBus.trigger("taskEvent", DailyTasks.RECEIVE_BONUS);
        }
    }
};

Yatzy.prototype.calcFirstColumnScore = function () {
    var score = 0;
    for (var i = 0; i < 6; i++) {
        if (this.slots[i].used || this.slots[i].selected) {
            score += this.slots[i].score;
        }
    }
    this.setBonus(score);
};

Yatzy.prototype.setGameMode = function (mode) {
    if (mode === Yatzy.SOLO && this.opponentScore !== undefined) {
        delete this.opponentScore;
    }
    if (mode === Yatzy.VERSUS && this.opponentScore === undefined) {
        this.opponentScore = new Score(this.calculateOpponentScore());
    }

    var scene = cleverapps.scenes.getRunningScene();
    scene.changeGameMode();
};

Yatzy.prototype.setOpponentResult = function () {
    var availableSlots = this.slots.filter(function (slot) {
        return !slot.opponentUsed;
    });
    var slot = Math.floor(Math.random() * availableSlots.length);
    slot = availableSlots[slot].id;
    var score = 0;

    if ([Slot.ONES, Slot.TWOS, Slot.THREES, Slot.FOURS, Slot.FIVES, Slot.SIXES].indexOf(slot) !== -1) {
        score = Math.floor(Math.random() * 5) * (slot + 1);
    }

    switch (slot) {
        case Slot.THREE_OF_A_KIND:
            score = Math.floor(Math.random() * 26 + 5);
            break;
        case Slot.FOUR_OF_A_KIND:
            score = Math.floor(Math.random() * 26 + 5);
            break;
        case Slot.SMALL_STRAIGHT:
            score = Math.floor(Math.random() > 0.2 ? 1 : 0) * 30;
            break;
        case Slot.LARGE_STRAIGHT:
            score = Math.floor(Math.random() > 0.3 ? 1 : 0) * 40;
            break;
        case Slot.FULL_HOUSE:
            score = Math.floor(Math.random() > 0.25 ? 1 : 0) * 25;
            break;
        case Slot.YATZY:
            score = Math.floor(Math.random() > 0.9 ? 1 : 0) * 50;
            break;
        case Slot.CHANCE:
            score = Math.floor(Math.random() * 26 + 5);
            break;
    }

    this.slots[slot].setOpponentScore(score);

    return score;
};

Yatzy.prototype.calculateOpponentScore = function () {
    var score = 0;
    this.slots.forEach(function (slot) {
        score += slot.opponentScore || 0;
    });
    return score;
};

Yatzy.prototype.showScreen = function (f, silent) {
    this.once("showFieldEnd", f);
    this.trigger("showField", silent);
    this.trigger("showDices", silent);
    this.trigger("showResults", silent);
    this.trigger("showRollButton", silent);

    cleverapps.audio.playSound(bundles.game.urls.window_game);

    if (!this.levelWithTutorial()) {
        this.trigger("showDicesDescription");
    }
};

Yatzy.prototype.hideScreen = function (f) {
    cleverapps.meta.compound(f, [
        function (f) {
            this.once("hideFieldEnd", f);
            this.trigger("hideField");
            this.trigger("hideDices");
        }.bind(this),

        function (f) {
            this.trigger("hideResults");
            this.trigger("hideRollButton");
            setTimeout(f, 500);
        }.bind(this)
    ]);
};

Yatzy.prototype.roll = function (auto) {
    if (this.noRollsLeft()) {
        return;
    }

    if (this.counter.isActive()) {
        return;
    }

    var toRoll = this.dices.filter(function (dice) {
        return !dice.chosen;
    });

    if (toRoll.length === 0) {
        return;
    }

    var suggestedRolls = this.getSuggestedRolls();
    this.rolled = true;

    toRoll.forEach(function (dice) {
        var suggested = suggestedRolls && suggestedRolls[dice.id];

        this.counter.inc();
        dice.roll(suggested, function () {
            this.counter.setTimeout(function () { }, 200);
            this.counter.dec();
        }.bind(this));
    }, this);

    this.removeRoll(auto);

    if (aisensia.tutorial.isActive()) {
        aisensia.tutorial.waitFinishStep();
    }

    if (!auto && !aisensia.tutorial.isActive()) {
        cleverapps.eventBus.trigger("taskEvent", DailyTasks.MAKE_ROLL);
    }

    cleverapps.audio.playSound(auto ? bundles.game.urls.rotation : bundles.game.urls.roll);
};

Yatzy.prototype.getSuggestedRolls = function () {
    var suggestedRolls = [];
    if (aisensia.tutorial.isActive()) {
        suggestedRolls = aisensia.tutorial.step().roll;
    } else if (cleverapps.config.debugMode && this.suggestedRolls) {
        suggestedRolls = this.suggestedRolls;
        this.suggestedRolls = undefined;
    }

    return suggestedRolls;
};

Yatzy.prototype.onAcceptProlongationOffer = function (offer) {
    GameBase.prototype.onAcceptProlongationOffer.call(this, offer);
    this.roll();
};

Yatzy.prototype.getProlongationMovies = function () {
    return 1;
};

Yatzy.prototype.сollectMarks = function (f) {
    var dicesWithMarks = this.dices.filter(function (dice) {
        return dice.mark;
    });
    var callback = cleverapps.wait(dicesWithMarks.length, f);
    dicesWithMarks.forEach(function (dice, index) {
        this.counter.setTimeout(dice.collectMark.bind(dice, callback), 200 * index);
    }.bind(this));
};

Yatzy.prototype.triggerTaskEvents = function (playedSlot) {
    if (playedSlot.id === Slot.FOUR_OF_A_KIND && playedSlot.score) {
        cleverapps.eventBus.trigger("taskEvent", DailyTasks.MAKE_FOUR_OF_A_KIND);
    }

    if (playedSlot.id === Slot.FULL_HOUSE && playedSlot.score) {
        cleverapps.eventBus.trigger("taskEvent", DailyTasks.MAKE_FULL_HOUSE);
    }

    if (playedSlot.id === Slot.SMALL_STRAIGHT && playedSlot.score) {
        cleverapps.eventBus.trigger("taskEvent", DailyTasks.MAKE_SMALL_STRAIGHT);
    }

    if (playedSlot.id === Slot.LARGE_STRAIGHT && playedSlot.score) {
        cleverapps.eventBus.trigger("taskEvent", DailyTasks.MAKE_LARGE_STRAIGHT);
    }

    if (playedSlot.score === Slot.YATZY_SCORE) {
        cleverapps.eventBus.trigger("taskEvent", DailyTasks.MAKE_YATZY);
    }
};

Yatzy.prototype.hasBegan = function () {
    return Boolean(this.rollCount) || Boolean(this.playCount);
};

Yatzy.prototype.getDiceCount = function () {
    return this.dices.reduce(function (diceCount, dice) {
        if (dice.score) {
            diceCount[dice.score] = diceCount[dice.score] !== undefined ? diceCount[dice.score] + 1 : 1;
        }
        return diceCount;
    }, []);
};

Yatzy.BONUS_REWARD = 35;
Yatzy.BONUS_GOAL = 63;

Yatzy.MAX_ROLLS = 3;

Yatzy.SOLO = 1;
Yatzy.VERSUS = 2;
