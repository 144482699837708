/**
 * Created by mac on 7/12/23
 */

var Dice = function (id, save) {
    cleverapps.EventEmitter.call(this);

    this.id = id;

    this.score = save && save.score || undefined;
    this.chosen = save && save.chosen || false;

    if (this.score) {
        this.addRegularMarks();
    }
};

Dice.prototype = Object.create(cleverapps.EventEmitter.prototype);
Dice.prototype.constructor = Dice;

Dice.prototype.getInfo = function () {
    return {
        score: this.score,
        chosen: this.chosen
    };
};

Dice.prototype.onClick = function () {
    if (this.score) {
        this.setChosen(!this.chosen);
    }

    if (aisensia.tutorial.isActive()) {
        aisensia.tutorial.waitFinishStep();
    }
};

Dice.prototype.setChosen = function (chosen) {
    if (this.chosen !== chosen) {
        this.chosen = chosen;
        this.trigger("changeChosen", chosen);
    }
};

Dice.prototype.roll = function (suggested, cb) {
    var previousScore = this.score ? this.score : this.id + 1;
    this.score = suggested || Math.floor(Math.random() * 6 + 1);
    this.addRegularMarks();
    this.trigger("roll", previousScore, cb);
};

Dice.prototype.collectMark = function (callback) {
    if (this.mark === undefined) {
        callback();
        return;
    }
    this.mark.collect(callback);
    this.mark = undefined;
};

Dice.prototype.addRegularMarks = function () {
    this.mark = undefined;
    if (Game.currentGame.getMissionType() === Mission.TYPE_LETTER && this.score === 6) {
        this.mark = new Mark("letter");
    }
};

Dice.prototype.hide = function () {
    this.setChosen(false);
    this.trigger("hide");
};

Dice.prototype.flight = function (slot, callback) {
    this.setChosen(false);
    this.trigger("flight", slot, callback);
};
