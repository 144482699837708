/**
 * Created by mac on 8/20/23
 */

var YatzyFieldView = cc.Node.extend({
    avoidNode: "YatzyFieldView",
    ctor: function (game) {
        this._super();

        this.game = game;

        var isDouble = this.isDouble = game.opponentScore !== undefined;

        this.slots = this.game.slots.map(function (dice) {
            return new SlotView(dice, isDouble);
        });

        var columns = this.createColumns();

        var columnsWrapped = cleverapps.UI.wrapWithPadding(columns, cleverapps.styles.YatzyFieldView.padding);
        this.setAnchorPoint(0.5, 0.5);

        this.setContentSize2(columnsWrapped.width, columnsWrapped.height);
        columnsWrapped.setPosition(this.width / 2, this.height / 2);

        var background = this.background = cleverapps.UI.createScale9Sprite(bundles.bg.frames.dices_bg);
        background.setAnchorPoint(0, 0);
        background.setContentSize2(columnsWrapped.width, columnsWrapped.height);

        this.addChild(background);
        this.addChild(columnsWrapped);

        this.visible = false;
        game.on("showField", this.animateShow.bind(this), this);
        game.on("hideField", this.animateHide.bind(this), this);
        game.on("updateBonus", this.updateBonus.bind(this), this);
        game.on("receiveBonus", this.receiveBonus.bind(this), this);
    },

    addBonusProgress: function (placeholder) {
        var styles = cleverapps.styles.YatzyFieldView.bonus;

        this.progressBar = new ScaledProgressBar({
            type: ScaledProgressBar.Types.green_large,
            barText: {
                font: cleverapps.styles.FONTS.STARS_PROGRESS_BAR_TEXT
            }
        });
        this.progressBar.setLength(styles.progressBar.width);
        this.progressBar.setGoal(Yatzy.BONUS_GOAL);
        this.progressBar.setPercentage(this.game.bonus);
        this.progressBar.setPositionRound(styles.progressBar);
        this.progressBar.setCascadeOpacityEnabledRecursively(true);

        var prize = this.prize = new cc.Sprite(bundles.bg.frames.bonus_bar_icon);
        prize.setPositionRound(styles.prize);
        this.progressBar.addChild(prize, 2);

        cleverapps.tooltipManager.create(prize, {
            text: "bonusDescription",
            position: cleverapps.styles.UI.Tooltip.LOCATION_ABOVE,
            interactiveScale: false
        });

        var prizeAmountBg = this.prizeAmountBg = new cc.Sprite(bundles.bg.frames.bonus_bar_icon_bg);
        prizeAmountBg.setPositionRound(styles.amount);
        prize.addChild(prizeAmountBg);

        var prizeAmount = cleverapps.UI.generateImageText("+" + Yatzy.BONUS_REWARD, cleverapps.styles.FONTS.WHITE_TEXT);
        prizeAmount.setPositionRound(prizeAmountBg.width / 2, prizeAmountBg.height / 2);
        prizeAmount.setFontSize(35);
        prizeAmountBg.addChild(prizeAmount);

        placeholder.addChild(this.progressBar);

        if (this.game.bonusReceived) {
            this.progressBar.setVisible(false);
        }
    },

    updateBonus: function () {
        this.progressBar.setPercentage(this.game.bonus, { animated: true });
    },

    animateProgressComplete: function () {
        var bonusShine = new cleverapps.Spine(bundles.yatzy.jsons.bonus_shine_json);
        bonusShine.setPositionRound(cleverapps.styles.YatzyFieldView.bonus.shine);
        this.progressBar.addChild(bonusShine, 1);
        bonusShine.setAnimation(0, "animation", true);
        bonusShine.setCompleteListener(function () {
            AnimationsLibrary.rays(this.prize);
            bonusShine.setCompleteListener();
            this.prizeAmountBg.runAction(new cc.Spawn(
                new cc.FadeOut(0.3),
                new cc.Sequence(
                    new cc.ScaleTo(0.1, 1.2),
                    new cc.ScaleTo(0.2, 0)
                )
            ));
        }.bind(this));
        this.progressBar.runAction(new cc.Sequence(
            new cc.DelayTime(2.5),
            new cc.CallFunc(function () {
                bonusShine.removeFromParent(true);
            }),
            new cc.Spawn(
                new cc.ScaleBy(0.1, 0.3),
                new cc.FadeOut(0.1)
            ),
            new cc.Hide()
        ));
    },

    receiveBonus: function (cb) {
        this.animateProgressComplete();

        this.slots.slice(0, 6).forEach(function (slot, index) {
            slot.animateBonusReceive(index * 0.3 + 0.5);
        });

        var scene = cleverapps.scenes.getRunningScene();
        var movingNode = cleverapps.scenes.getMovingNode(this);
        var flyPrize = new cleverapps.Spine(bundles.yatzy.jsons.bonus_gift_json);
        var target = cc.p(scene.width / 2, scene.height / 2);
        flyPrize.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
        this.prize.addChild(flyPrize);
        flyPrize.setVisible(false);
        flyPrize.replaceParentSamePlace(movingNode, { keepScale: true });
        var jumpOffset = cc.pSub(target, flyPrize.getPosition());
        flyPrize.runAction(new cc.Sequence(
            new cc.DelayTime(2.3),
            new cc.CallFunc(function () {
                this.prize.setVisible(false);
                flyPrize.setVisible(true);
                flyPrize.setAnimation(0, "open", false);

                cleverapps.audio.playSound(bundles.game.urls.present);
            }.bind(this)),
            new cc.Spawn(
                new cc.JumpAnimation(0.6, flyPrize, jumpOffset, -600),
                new cc.ScaleTo(0.6, 1.6)
            ),
            new cc.DelayTime(0.4),
            new cc.CallFunc(function () {
                var bonusScore = cleverapps.UI.generateImageText("+" + Yatzy.BONUS_REWARD, cleverapps.styles.FONTS.SCORE_TEXT);
                var bonusScoreRays;
                bonusScore.setPosition(flyPrize.getPosition());
                movingNode.addChild(bonusScore);
                bonusScore.setOpacity(0);
                bonusScore.runAction(new cc.Sequence(
                    cc.spawn(
                        new cc.MoveBy(0.1, 0, 100),
                        new cc.FadeIn(0.1)
                    ),
                    new cc.CallFunc(function () {
                        bonusScoreRays = AnimationsLibrary.rays(bonusScore);
                    }),
                    new cc.DelayTime(0.4),
                    new cc.CallFunc(function () {
                        bonusScoreRays.removeFromParent();
                    }),
                    new cc.MoveTo(0.3, bonusScore.getRelativeCoordinates(cleverapps.aims.getTarget("score"))).easing(cc.easeOut(1)),
                    new cc.RemoveSelf()
                ));
            }),
            new cc.DelayTime(0.3),
            new cc.CallFunc(function () {
                flyPrize.setAnimation(0, "hide", false);
            }),
            new cc.MoveBy(0.3, 0, -scene.width / 2 - scene.y),
            new cc.FadeOut(0),
            new cc.CallFunc(function () {
                cb();
            }),
            new cc.RemoveSelf()
        ));
    },

    _listInnerContent: function () {
        return [this.progressBar, this.bonusLines].concat(this.slots);
    },

    animateShow: function (silent) {
        this.visible = true;

        if (silent) {
            this.game.trigger("showFieldEnd");
            return;
        }

        var innerContent = this._listInnerContent();
        innerContent.forEach(function (node) {
            node.setVisible(false);
        });
        this.background.setOpacity(0);

        var styles = cleverapps.styles.YatzyFieldView.showAnimation;

        this.runAction(
            new cc.Sequence(
                new cc.MoveBy(0, -styles.dx, -styles.dy),
                new cc.Spawn(
                    new cc.TargetedAction(this.background, new cc.FadeIn(0.3)),
                    new cc.MoveTo(0.3, this.x, this.y)
                ),
                new cc.CallFunc(function () {
                    innerContent.forEach(function (node) {
                        node.setVisible(true);
                        node.setCascadeOpacityEnabledRecursively(true);
                        node.setOpacity(0);
                        node.runAction(new cc.FadeIn(0.3));
                    });
                }),
                new cc.CallFunc(function () {
                    this.game.trigger("showFieldEnd");
                }.bind(this))
            )
        );
    },

    animateHide: function () {
        var innerContent = this._listInnerContent();
        var styles = cleverapps.styles.YatzyFieldView.showAnimation;

        this.setCascadeOpacityEnabled(true);

        this.runAction(
            new cc.Sequence(
                new cc.CallFunc(function () {
                    innerContent.forEach(function (node) {
                        node.setCascadeOpacityEnabledRecursively(true);
                        node.runAction(new cc.FadeOut(0.3));
                    });
                }),
                new cc.DelayTime(0.3),

                new cc.CallFunc(function () {
                    this.game.trigger("hideFieldEnd");
                }.bind(this)),

                new cc.Spawn(
                    new cc.MoveBy(0.3, -styles.dx, -styles.dy),
                    new cc.FadeOut(0.3)
                )
            )
        );
    },

    createColumns: function () {
        var placeholder = new cc.Node();
        placeholder.setAnchorPoint(0.5, 0.5);
        placeholder.setContentSize2(this.slots[0].getContentSize());

        var column1 = [this.createColumnNames(this.isDouble)].concat(this.slots.slice(0, 6).concat([placeholder]));
        column1 = this.column1 = new cleverapps.Layout(column1, {
            direction: cleverapps.UI.VERTICAL,
            margin: cleverapps.styles.YatzyFieldView.columns.slot_margin
        });

        this.addBonusLine(column1);
        this.addBonusProgress(placeholder);

        var column2 = [this.createColumnNames(this.isDouble)].concat(this.slots.slice(6, 13));
        column2 = this.column2 = new cleverapps.Layout(column2, {
            direction: cleverapps.UI.VERTICAL,
            margin: cleverapps.styles.YatzyFieldView.columns.slot_margin
        });

        var columns = new cleverapps.Layout([column1, column2], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: cleverapps.styles.YatzyFieldView.columns.margin
        });

        columns.setPositionRound(this.width / 2, this.height / 2);
        return columns;
    },

    addBonusLine: function (column) {
        var styles = cleverapps.styles.YatzyFieldView.bonus;

        var bonusLine = [];
        for (var i = 0; i < 5; i++) {
            var sprite = cleverapps.UI.createScale9Sprite(bundles.bg.frames.bonus_line);
            sprite.setContentSize2(styles.line);
            bonusLine.push(sprite);
        }
        sprite = cleverapps.UI.createScale9Sprite(bundles.bg.frames.bonus_line);
        sprite.setContentSize2(styles.line.lastLine);
        bonusLine.push(sprite);

        var lines = new cleverapps.Layout(bonusLine, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.line.margin
        });
        lines.setPositionRound(styles.line);
        column.addChild(lines, -1);

        lines.setCascadeOpacityEnabled(true);
        this.bonusLines = lines;
    },

    createColumnNames: function (isDouble) {
        var columnNames = new cc.Node();
        columnNames.setAnchorPoint(0.5, 0.5);
        columnNames.setContentSize(this.slots[0].getContentSize().width, cleverapps.styles.YatzyFieldView.columns.column_name_text.height);
        if (!isDouble) {
            return columnNames;
        }

        var firstColumnText = cleverapps.UI.generateOnlyText("you", cleverapps.styles.FONTS.SLOT_TITLE);
        firstColumnText.setPositionRound(cleverapps.styles.YatzyFieldView.columns.column_name_text.you);
        columnNames.addChild(firstColumnText);

        var secondColumnText = cleverapps.UI.generateOnlyText("them", cleverapps.styles.FONTS.SLOT_TITLE);
        secondColumnText.setPositionRound(cleverapps.styles.YatzyFieldView.columns.column_name_text.them);
        columnNames.addChild(secondColumnText);

        return columnNames;
    }
});

cleverapps.styles.YatzyFieldView = {
    padding: {
        left: 50,
        right: 50,
        top: 40,
        bottom: 40
    },

    showAnimation: {
        dx: 100,
        dy: 0
    },

    columns: {
        column_name_text: {
            height: 30,
            you: {
                x: { align: "center", dx: -15 },
                y: { align: "center", dy: 0 }
            },
            them: {
                x: { align: "right", dx: -40 },
                y: { align: "center", dy: 0 }
            }
        },
        text: {
            x: { align: "center", dx: -60 },
            y: { align: "center", dy: -20 }
        },
        slot_margin: 16,
        margin: 80
    },

    bonus: {
        line: {
            lastLine: {
                width: 31,
                height: 65
            },
            x: { align: "center", dx: -10 },
            y: { align: "center", dy: -28 },
            margin: 98,
            width: 31,
            height: 55
        },
        progressBar: {
            x: { align: "center", anchor: false, dx: -20 },
            y: { align: "center", anchor: false, dy: 10 },
            width: 380
        },
        prize: {
            x: { align: "right", anchor: false, dx: 0 },
            y: { align: "center", dy: 14 }
        },
        amount: {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", anchor: false, dy: 0 }
        },
        shine: {
            x: { align: "center", dx: 20 },
            y: { align: "center", dy: 5 }
        }
    }
};